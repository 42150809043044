// src/Home.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FloatingForm from './components/FloatingForm';
import Signup from './components/Signup';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/navbar';
import Team from './components/team';
import StatsCounter from './components/StatsCounter';
import HeroSection from './components/hero';
import Footer from './components/footer';


const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Navbar/>
      </header>  
        <HeroSection/>
        <FloatingForm/>  
        <br/>
        <StatsCounter/>
        <Team/>
      <Routes>
        <Route path="/" element={<FloatingForm />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<ProtectedRoute component={AdminDashboard} />} />
      </Routes>
      <Footer/>
    </div>
  );
};

export default Home;
