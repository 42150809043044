import React, { useState, useEffect } from 'react';
import './css/FloatingForm.css';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const FloatingForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [visible, setVisible] = useState(true);

  // Función para ocultar el formulario después de 60 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 60000); // 60000 milisegundos = 60 segundos

    return () => clearTimeout(timer); // Limpiar el timer al desmontar el componente
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumber = '+56939357975'; // Número de WhatsApp de destino
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      `Nombre: ${firstName} ${lastName}\nEmail: ${email}\nTeléfono: ${phone}\nNota: ${note}`
    )}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <Container>
      {/* Formulario visible solo en pantallas grandes */}
      <div className={`floating-form-container desktop-form ${visible ? '' : 'hidden'}`}>
        <Form onSubmit={handleSubmit} className="floating-form">
          <h2>Contáctanos</h2>
          <FloatingLabel controlId="floatingFirstName" label="Nombre" className="mb-3">
            <Form.Control
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingLastName" label="Apellidos" className="mb-3">
            <Form.Control
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingEmail" label="Email" className="mb-3">
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPhone" label="Teléfono" className="mb-3">
            <Form.Control
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingNote" label="Nota" className="mb-3">
            <Form.Control
              as="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              required
              style={{ height: '100px' }}
            />
          </FloatingLabel>
          <Button variant="primary" type="submit">
            Enviar
          </Button>
        </Form>
      </div>

      {/* Ícono flotante de llamada */}
      <a
        href="tel:+56939357975"
        className="call-icon"
      >
        <i className="bi bi-telephone-fill"></i>
      </a>

      {/* Ícono flotante de WhatsApp */}
      <a
        href="https://wa.me/56939357975"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <i className="bi bi-whatsapp"></i>
      </a>
    </Container>
  );
};

export default FloatingForm;
