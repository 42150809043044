import React from 'react';
import logoC from './assets/img/favicon.jpg';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="navbar-collapse">
          <div className="d-flex align-items-center">
            <img src={logoC} className="navbar-brand mb-0 h1 d-lg-none" height={100} width={100} alt="logo" />

            <h5 className="mb-0 ms-3">
              CESMED <strong>Mas Salud</strong>
            </h5>
          </div>

        </div>
      </div>
    </nav>
  );
}

export default Navbar;
