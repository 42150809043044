import React from 'react';
import centro from './assets/img/bg1.jpg';

function HeroSection() {
  return (
    <div className="text-left">
      <a href="https://wa.me/56939357975">
        <img
          className="rounded d-block mx-auto mb-4"
          src={centro}
          alt="Centro"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </a>

      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6 order-lg-last"> {/* En pantallas grandes, el video estará en la segunda columna */}
            <iframe
              width="100%"
              height="215"
              src="https://www.youtube.com/embed/0oGW8MIzWOo?si=AQwXbS2lq5-iXGxj"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ marginTop: '150px', marginRight: '10px' }}
            ></iframe>
          </div>
          <div className="col-lg-6 order-lg-first"> {/* En pantallas grandes, el texto estará en la primera columna */}
            <div className="text-start">
              <br/><br/><br/>
              <h5><strong>Telemedicina</strong></h5>
              <p>
                Somos una empresa con profesionales especializados en consultas médicas online y domiciliarias, para que usted sea atendido desde la comodidad de su hogar, sin filas interminables y más tiempo para usted.
              </p>
              <br/>
              <h5><strong>Domiciliarias</strong></h5>
              
              <p>
                Nuestros profesionales dan <strong>consultas domiciliarias</strong>, para que sea atendido en la comodidad de su hogar, como ud se merece.
              </p>
              <h5>
                <strong> Hospitalizacion domiciliaria </strong>
              </h5>
              <p>Que comprende diagnostico y tratamiento de la patologia aguda que presenten nuestros pacientes</p>
              <br/>
              {/* Botón para la web externa */}
              <a href="https://beta-sacmed.novacaribe.com/ReservaOnline/11437" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-primary">Agende con nosotros</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
