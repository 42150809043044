import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import img1 from './assets/img/img1.jpg';
import img2 from './assets/img/img2.png';
import img3 from './assets/img/img3.jpg';
import img4 from './assets/img/img4.png';
import img5 from './assets/img/img5.jpg';
import img6 from './assets/img/img6.jpg';
import img7 from './assets/img/img7.jpg';

export default function Team() {
  const teamMembers = [
    {
      img: img1,
      name: "Visitas domiciliarias",
      profession: "",
      info: [
        "Nuestros especialistas en medicina familiar, recibidos en Cuba siempre están dispuestos para brindar una atención de excelencia.",
        "Medicina familiar",
        "Podología clínica",
        "Hospitalización domiciliaria",
        "Kinesioterapia respiratoria y musculoesquelética",
      ],
    },
    {
      img: img2,
      name: "Consultas de salud mental",
      profession: "",
      info: [
        "Siempre creemos que uno de los pilares de la salud es la estabilidad mental y emocional, donde nuestros profesionales dupla Médico-Psicólogo siempre dispuestos para atender, apoyar y brindar una atención de primera.",
        "Médico de salud mental",
        "Psicólogo",
      ],
    },
    {
      img: img5,
      name: "Toma de muestras a domicilio",
      profession: "Laboratorio Clínico Ariztía",
      info: [
        "Independencia 422, local 4",
        "Cotice su atención al WhatsApp +56938800510",
        "Domicilios solo en Ovalle y alrededores",
        "Nuestros profesionales de enfermería y laboratorio clínico, siempre están dispuestos para brindar una atención de excelencia.",
      ],
    },
    {
      img: img3,
      name: "Consultas de telemedicina",
      profession: "",
      info: [
        "Les brindamos una atención de excelencia, para que sea atendido desde la comodidad de su casa a la distancia de un clic.",
        "Medicina general.",
        "Psicología.",
        "Nutrición.",
      ],
    },
    {
      img: img7,
      name: "Aplicación de tratamientos ambulatorios",
      profession: "Médico & Enfermera",
      info: [
        "Les brindamos una atención de excelencia, para que sea atendido desde la comodidad de su casa a la distancia de un clic.",
        "Tratamientos intravenosos.",
        "Tratamientos intramusculares.",
        "Vacunación.",
        "Curaciones.",
      ],
    },
    {
      img: img6,
      name: "Kinesiología",
      profession: "",
      info: [
        "No hay salud completa si tu cuerpo no está bien, y por eso la rehabilitación es uno de nuestros pilares.",
        "Kinesioterapia respiratoria",
        "Kinesioterapia motora",
        "Kinesioterapia a embarazadas",
        "Rehabilitación cardiopulmonar",
      ],
    },
    {
      img: img4,
      name: "Podología clínica",
      profession: "",
      info: [
        "Tenemos profesionales encargados de atender sus necesidades en todo tipo de patologías de los pies.",
        "Enfermería",
        "Podología",
      ],
    },
  ];

  return (
    <Container>
      <section className="team text-center">
        <div className="team_title my-5">
          <h2>Nuestros servicios</h2>
          <p>
            Creemos que un servicio de excelencia es la clave para responder eficazmente, en el cumplimiento dentro de lo posible las necesidades de nuestros usuarios.
          </p>
        </div>
        <Row>
          {teamMembers.map((member, index) => (
            <Col key={index} lg={3} md={6} className="mb-4">
              <Card className="h-100 shadow-sm">
                <div
                  className="member-img"
                  onClick={() => window.open('https://wa.me/56939357975', '_blank')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Img
                    variant="top"
                    src={member.img}
                    className="rounded-circle"
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                      margin: '0 auto',
                      paddingTop: '15px',
                    }}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text className="text-muted">{member.profession}</Card.Text>
                  <ul className="list-unstyled text-start">
                    {member.info.map((info, i) => (
                      <li key={i} className="info mb-2">
                        {info}
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
}
