// src/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import Signup from './Signup';
import { Container } from 'react-bootstrap';

const AdminDashboard = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        setUser(currentUser);
        setIsAdmin(userDoc.data().role === 'admin');
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, []);

  if (!user) {
    return <p>Please log in</p>;
  }

  if (!isAdmin) {
    return <p>You are not authorized to access this page</p>;
  }

  return (
    <Container>
      <h2>Admin Dashboard</h2>
      <Signup />
    </Container>
  );
};

export default AdminDashboard;
