import React, { useEffect, useState } from 'react';
import './css/StatsCounter.css';
import { db } from './firebaseConfig';
import { doc, getDoc, setDoc, increment, onSnapshot } from "firebase/firestore";

const StatsCounter = () => {
    const [totalAtenciones, setTotalAtenciones] = useState(0);
    const [visitasSitio, setVisitasSitio] = useState(0);
    const [tiempoRubro, setTiempoRubro] = useState('3 años');

    const visitasRef = doc(db, "estadisticas", "visitas");

    useEffect(() => {
        // Obtener y actualizar estadísticas en tiempo real desde Firestore
        const unsubscribe = onSnapshot(visitasRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setTotalAtenciones(data.totalAtenciones || 789);
                setVisitasSitio(data.visitasSitio || 983);
                setTiempoRubro(data.tiempoRubro || '3 años');
            }
        });

        // Incrementar el contador de visitas cada vez que el componente se monta
        const incrementarVisita = async () => {
            await setDoc(visitasRef, { visitasSitio: increment(1) }, { merge: true });
        };
        incrementarVisita();

        // Limpiar el listener cuando el componente se desmonte
        return () => unsubscribe();
    }, []);

    return (
        <div className="stats-container">
            <div className="stat-item">
                <h1>{totalAtenciones}</h1>
                <h3>Total de Atenciones</h3>
            </div>
            <div className="stat-item">
                <h1>{visitasSitio}</h1>
                <h3>Visitas al Sitio</h3>
            </div>
            <div className="stat-item">
                <h1>{tiempoRubro}</h1>
                <h3>Tiempo en el Rubro</h3>
            </div>
        </div>
    );
};

export default StatsCounter;